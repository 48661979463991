//.page_index {}

.index_info {
  background-color: #e7e7e7;
  text-align: center;

  &__text {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    line-height: 1.15;
    color: #1b1b1b;
  }

  .h1, h1 {
    font-weight: 700;
    font-size: 32px;
    color: currentColor;
  }

  .h3, h3 {
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
    color: currentColor;
  }
}

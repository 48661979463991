.benefits {
  background-color: #60a660;
}

.benefits_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    padding-top: 49px;
    padding-bottom: 49px;
    font-weight: 700;
    font-size: 23px;
    color: #fff;

    background-repeat: no-repeat;
    background-position: left center;

    &--1 {
      padding-left: 75px;
      background-image: url("../images/padlock.png");
    }
    &--2 {
      padding-left: 80px;
      background-image: url("../images/clock.png");
    }
    &--3 {
      padding-left: 100px;
      background-image: url("../images/laptop.png");
    }
  }
  @include onlyMD {
    &__item {
      font-size: 16px;
    }
  }
  @include maxSM {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;

    &__item {
      padding-top: 75px;
      padding-bottom: 0;
      padding-left: 0;
      background-position: top center;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
  }
}

.menu {
  padding-top: 20px;
  padding-bottom: 20px;

  font-size: 14px;
  text-align: center;

  border-bottom: 1px solid #000;

  &__item {
    display: inline-block;
  }
  &__link {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;

    font-size: 16px;
    line-height: 1.5;
    color: #1b1b1b;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include maxSM {
    &__item {
      display: block;

      & + & {
        border-top: 1px solid #1a0fa6;
      }
    }
    &__link {
      display: block;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

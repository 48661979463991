.form {
  &__header {
    padding-top: 20px;
    padding-bottom: 20px;

    font-size: 20px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    background-color: #000;
  }

  &__body {
    padding: 20px;
  }

  &__footer {
    padding-bottom: 10px;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__text {
    color: #3c3c3c;

    a {
      color: #006300;
      text-decoration: underline;
      &:hover {
        color: #b5babc;
      }
    }
  }

  .btn {
    width: 100%;
  }

  .comodo_logo {
    display: flex;
    align-items: center;
    width: 200px;
    height: 60px;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;

    &__img {
      flex-shrink: 0;
      vertical-align: middle;
    }

    &:before {
      content: "SSL Secure Connection";
      display: block;
      width: 75px;
      margin-top: 10px;
      margin-right: 10px;
      flex-shrink: 0;
      font-size: 13px;
      font-weight: 700;
      color: #79c400;
    }
  }

  label.error {
    font-size: 11px;
    color: #f20;
  }

  &--short {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #6691a2;
    box-shadow: 0 0 5px #b1b1b1;
  }
}

.input,
.select {
  width: 100%;
  height: 44px;
  padding-right: 10px;
  padding-left: 10px;

  border-radius: 5px;
  border: 1px solid #dedede;
  background-color: #fbfbfb;
  outline: none;

  font-size: 15px;
  color: #444;

  &:focus {
    border-color: #43b9dd;
    color: #43b9dd;
  }

  &::placeholder {
    color: #444;
  }
}

.input {
  &.error {
    border-color: #f20;
    background-color: rgba(255, 34, 0, 0.3);
  }
}

//.select {}

.btn {
  display: inline-block;
  padding-top: 11px;
  padding-bottom: 11px;
  background-color: #009c00;
  box-shadow: 0 3px 9px 0 rgba(#000, 0.42);
  border-radius: 15px;
  border: 2px solid #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;

  font-size: 21px;
  font-weight: 500;
  text-align: center;
  color: #fff;

  text-decoration: none;

  &:hover {
    box-shadow: inset 0 3px 9px 0 rgba(#000, 0.42);
    text-decoration: none;
  }
}

.label {
  &__text {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 700;
  }
}

.message {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 1.25;

  &--success {
    color: green;
  }

  &--error {
    color: #f20;
  }
}

.unsubscribe {
  &__title {
    text-align: center;
  }

  .form--unsubscribe {
    @include maxSM {
      margin-bottom: 20px;
    }
  }

  .btn {
    border: none;
  }
}

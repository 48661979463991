$lg: 1260px;
$md: 1024px;
$sm: 768px;
$xs: 480px;

$gutter: 15px;
$max_width: 1000px;

$sm-1: ($sm - 1);
$md-1: ($md - 1);
$lg-1: ($lg - 1);

$smallerThanSM: "only screen and (max-width: #{$sm-1})";
$smallerThanMD: "only screen and (max-width: #{$md-1})";
$smallerThanLG: "only screen and (max-width: #{$lg-1})";

$largerThanXS: "only screen and (min-width: #{$sm})";
$largerThanSM: "only screen and (min-width: #{$md})";
$largerThanMD: "only screen and (min-width: #{$lg})";

$onlyXS: "only screen and (max-width: #{$sm-1})";
$onlySM: "only screen and (min-width: #{$sm}) and (max-width: #{$md-1})";
$onlyMD: "only screen and (min-width: #{$md}) and (max-width: #{$lg-1})";
$onlyLG: "only screen and (min-width: #{$lg})";

$headerHeight: 80px;
$headerHeightMobile: 45px;

@mixin maxLG {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin onlyLG {
  @media (min-width: $md + 1) and (max-width: $lg - 1) {
    @content;
  }
}

@mixin minLG {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin maxMD {
  @media (max-width: $md) {
    @content;
  }
}

@mixin onlyMD {
  @media (min-width: $sm + 1) and (max-width: $md - 1) {
    @content;
  }
}

@mixin minMD {
  @media (min-width: $md) {
    @content;
  }
}

@mixin maxSM {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin minSM {
  @media (min-width: $sm) {
    @content;
  }
}

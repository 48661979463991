.wysiwyg {
  *:last-child {
    margin-bottom: 0;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  p {
    margin-bottom: 15px;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-weight: 400;
  }

  h1,
  .h1 {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 30px;
  }
  h2,
  .h2 {
    font-size: 20px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
  h4,
  .h4 {
    font-size: 12px;
    font-weight: 700;
  }

  p,
  ul,
  ol {
    font-size: 16px;
  }

  ul,
  ol {
    margin-bottom: 20px;
  }

  ul {
    list-style: inside disc;
  }

  ol {
    list-style: inside decimal;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  table {
    margin-bottom: 15px;
    font-size: 16px;
    @include maxSM {
      font-size: 14px;
    }
  }

  th, td {
    span {
      display: inline;
      word-break: break-all;
    }
  }

  th {
    padding: 10px;
    border: 1px solid #e9ffff;
    background-color: #0585cb;
    color: #e9ffff;
    text-align: left;
    text-transform: uppercase;
    vertical-align: top;
    @include maxSM {
      padding: 5px;
    }
  }

  td {
    padding: 5px;
    border: 1px solid #ccc;
    vertical-align: top;
    border-spacing: 0;
    background-color: #fff;
  }
}

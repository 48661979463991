.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 24px;
  padding-bottom: 23px;
  background-color: #fff;
  box-shadow: 2px 2px 20px 0 rgba(#000, 0.3);
  transition: 0.6s;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include maxSM {
    padding-top: 6px;
    padding-bottom: 6px;

    &__phone {
      display: none;
    }
  }
}

.logo {
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &__txt {
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

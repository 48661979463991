.page_form {
  .form_holder {
    @include minSM {
      min-height: calc(100vh - #{$headerHeight} - 89px); // 89 - menu height
    }
    @include maxSM {
      min-height: initial;
    }
  }
}

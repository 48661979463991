.footer {
  background-color: #f6f6f6;
  border-top: 1px solid #000;

  &__disclosures {
    padding-top: 20px;
    padding-bottom: 20px;

    font-size: 14px;
    color: #757575;

    border-bottom: 1px solid #757575;

    .wysiwyg p {
      font-size: inherit;
    }
  }

  &__copyright {
    padding-top: 5px;
    padding-bottom: 5px;

    text-align: center;
  }

  @include maxSM {
    .container {
      display: flex;
      flex-direction: column;
    }

    &__disclosures {
      order: -1;
    }
  }
}
